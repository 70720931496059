.solText {
    color: green;
}

.text {
    font-size: 1.05em;
    margin-bottom: 0.8em;
    margin-top: 0.8em;
}

.heading {
    font-weight: 650;
}