.solText {
    color: green;
}

.text {
    font-size: 1.2em;
    margin-bottom: 0.8em;
    margin-top: 0.8em;
}

.heading {
    font-weight: 650;
}

.table {
    width: 100%;
}

.tableHeader {
    padding: 0.5em;
    text-align: left !important;
}

.tableData {
    padding: 0.5em;
    text-align: left;
}

tr:nth-child(even) {background-color: #f2f2f2;}