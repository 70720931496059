.response {
    cursor: pointer;
}

.noTab {
    outline: none;
}

.delete {
    cursor: pointer;
    float: right;
    padding-top: 1.25em;
}

.pupilText {
    padding: .375em .75em;
}

.summary {
    padding: 0 .75em;
    display: block;
    color: #777;
}