.option {
    cursor: pointer;
    padding: 0.75em 1em;
    border: solid 0.1em #ccc;
    border-radius: 0.3em;
    align-items: center;
    margin-top: 1em;
    display: block;
}

.option:focus {
    outline: solid 0.2em #000;
}

.disabled:focus {
    outline: solid 0.2em #000;
}

.chosen {
    border: solid 0.13em rgb(28, 122, 205);
}

.noTabs:focus {
    outline: none;
}

.disabled {
    cursor: not-allowed;
}

.correctAns {
    border: solid 0.13em rgb(125, 205, 28);
}

.incorrectlyChosen {
    border: solid 0.13em rgb(191, 12, 45);
}

.heading {
    font-weight: 650;
}