.solText {
    color: green;
}

.text {
    font-size: 1.2em;
    margin-bottom: 0.8em;
    margin-top: 0.8em;
}

.heading {
    font-weight: 650;
}

.padout {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
}

.padoutSol {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
}

.inputArea {
    display: flex;
    align-items: center;
}

.inputIndicator {
    padding-right: 0.1em;
    margin-right: 1em;
    color: rgb(90, 174, 226);
    font-size: 1.3em;
}

hr {
    background-color: rgb(213, 213, 213);
}

.input {
    padding-bottom: 0.6em;
}

.alertBtn {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}

.alertBtn:focus {
    outline: none;
}