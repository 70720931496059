.checkbox {
    color: rgb(62, 117, 220);
}

.checkbox:hover {
    color: rgb(47, 95, 183);
}

.checkableItem {
    cursor: pointer;
}

.noTab:focus {
    outline: none;
}