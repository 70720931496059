.wrapper {
    font-weight: 500;
    color: #666;
    border: solid 1px #ccc;
    border-radius: 0.25em;
    align-items: center;
    position: relative;
}

.progress {
    bottom: 0;
    right: 0;
    left: 0;
    height: 8px !important;
}

.text {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}