.response {
  cursor: pointer;
}

.noTab {
  outline: none;
}

.delete {
  cursor: pointer;
  float: right;
}