.rotate {
  transform: rotate(-90deg);
}

.icon {
  transition: transform 0.2s ease;
  display: inline-block;
}

.heading {
  display: inline-block;
  margin-right: 0.25em;
}

.showControls {
  cursor: pointer;
  display: inline-table;
}

.item {
  transition: max-height 0.2s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.item.collapsed {
  max-height: 0;
  transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
}

.overflow {
  overflow: visible;
}

.hide {
  overflow: hidden;
}

.noTab {
  outline: none;
}