@import '../node_modules/react-modal-video/css/modal-video.min.css';
.mcm-btn {
  background-color: rgb(131, 198, 242);
  color: white;
}

.mcm-btn:hover {
  color: white;
  background-color: rgb(108, 181, 230);
}

.page-link {
  cursor: pointer;
}

.page-item.active>.page-link {
  background-color: rgb(88, 173, 230);
  border-color: rgb(88, 173, 230);
}

.pagination {
  justify-content: center;
}

.progress {
  border-radius: 0.25em;
}