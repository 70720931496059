.title {
    cursor: pointer;
    border: solid 0.1em #ccc;
    border-radius: 0.2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.input {
    border: solid 0.1em #ccc;
    border-radius: 0.2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.3em;
    padding-right: 0.3em;
}

.title:hover {
    background-color: #F5F5F5;
}

.noTabs {
    outline: none;
}

.setHeight {
    height: 2.1em;
}

.ddItems {
    margin-top: 0.2em;
    padding-top: 0.1em;
    position: absolute;
    z-index: 999;
    border: 1px solid #ccc;
    border-radius: 3px;
    left: 0px;
    list-style: none;
    padding: 4px 0px;
    display: block;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .175);
    width: 100%;
    max-height: 20em;
    overflow: scroll;
}

.ddItem {
    padding-left: 0.5em;
    cursor: pointer;
    display: block;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    white-space: nowrap;
}

.ddItem:hover {
    background-color: #F5F5F5;
}

.ddItemActive {
    background-color: #F5F5F5;
}

.disabled {
    cursor: not-allowed ;
    color: #9f9f9f;
}

.disabled:hover {
    background-color: white;
}