.btn {
    border-radius: 0.25em;
    border: solid 0.1em #ccc;
    background-color: white;
}

.btn:hover {
    background-color: #F5F5F5;
}

.btn:active {
    color: #555;
}

.dark {
    background-color: rgb(28, 122, 205);
    color: white;
    border: none;
}

.dark:hover {
    background-color: rgb(25, 108, 181);
    color: white;
}

.dark:active {
    background-color: rgb(20, 97, 164);
    color: #ddd;
}

.light {
    background-color: rgb(131, 198, 242);
    color: white;
    border: none;
}

.light:hover {
    background-color: rgb(105, 176, 223);
    color: white;
}

.light:active {
    background-color: rgb(101, 167, 211);
    color: #ddd;
}

.disabled {
    cursor: not-allowed;
}

.danger {
    background-color: #d9534f;
    color: white;
    border: none;
}

.danger:hover {
    background-color: #a73430;
    color: white;
}

.danger:active {
    background-color: #8f2622;
    color: #ddd;
}

.success {
    background-color: #5cb85c;
    color: white;
    border: none;
}

.success:hover {
    background-color: #469846;
    color: white;
}

.success:active {
    background-color: #377c37;
    color: #ddd;
}