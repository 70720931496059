.wrapper {
    margin-top: 0.5em;
}

.item {
    overflow: hidden;
    transition: max-height 0.2s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.item.collapsed {
    max-height: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
}

.title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    border: solid 1px #ccc;
    border-radius: 0.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title:hover {
    color: black;
}

.title.open {
    color: black;
}

.content {
    padding: 0.5em 1.5em;
}

.rotate {
    transform: rotate(90deg);
}

.icon {
    margin-left: auto;
    transition: transform 0.2s ease;
}

.check {
    color: rgb(73, 175, 239);
}

.check:hover {
    color: rgb(66, 154, 210);
}

.noTab:focus {
    outline: none;
}