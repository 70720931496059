.label {
  text-align: left;
}

.input {
  border: solid 0.1em #ccc;
  border-radius: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}