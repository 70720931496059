.checkbox {
  margin-top: 0.05em;
  margin-right: 0.1em;
  color: rgb(62, 117, 220);
}

.checkbox:hover {
  color: rgb(47, 95, 183);
}

.checkableItem {
  cursor: pointer;
}

.noTab:focus {
  outline: none;
}