.wrapper {
    /* Size and position */
    position: relative;
    /* Enable absolute positioning for children and pseudo elements */
}

.title {
    cursor: pointer;
    border: solid 0.1em #ccc;
    border-radius: 0.2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.title:hover {
    background-color: #F5F5F5;
}

.ddItems {
    margin-top: 0.2em;
    padding-top: 0.1em;
    position: absolute;
    z-index: 999;
    border: 1px solid #ccc;
    border-radius: 3px;
    left: 0px;
    list-style: none;
    padding: 4px 0px;
    display: block;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .175);
    width: 100%;
}

.ddItem {
    cursor: pointer;
    display: block;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    white-space: nowrap;
}

.ddItem:hover {
    background-color: #F5F5F5;
}

.noTabs {
    outline: none;
}

.disabled {
    cursor: not-allowed;
    color: #bbb;
}