.padout {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
}

.inputArea {
    display: flex;
    align-items: center;
}

.inputIndicator {
    padding-right: 0.1em;
    margin-right: 1em;
    color: rgb(90, 174, 226);
    font-size: 1.3em;
}

hr {
    background-color: rgb(213, 213, 213);
}

.input {
    padding-bottom: 0.6em;
}

.alertBtn {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}

.next {
    background-color: rgb(90, 174, 226);
    color: white;
}

.next:hover {
    color: white;
    background-color: rgb(48, 142, 199);
}

.option {
    padding: 0.75em 1em;
    border: solid 0.1em #ccc;
    border-radius: 0.3em;
    align-items: center;
    margin-top: 1em;
    display: block;
}

.padNoInp {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.yesBtn {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: rgb(106, 164, 20);
}

.noBtn {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: rgb(206, 51, 51);
}